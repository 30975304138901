import {
  setCurrencies,
  setTemplates,
  setTypesColors,
} from "actions/mainActions";
import { Notification, toast } from "components/ui";
import { apiDeleteBot, apiStartBot, apiStopBot } from "services/BotsService";
import { apiGetInputTypes, apiGetTemplates } from "services/TemplatesService";
import { v4 as uuidv4 } from "uuid";

import jwt_decode from "jwt-decode";
import {
  CURRENCIES_STORE_NAME,
  REFRESH_TOKEN_STORE_NAME,
  SUBSCRIPTION_STORE_NAME,
  TEMPLATES_STORE_NAME,
  TOKEN_STORE_NAME,
} from "constants/app.constant";
export const OUTPUT_SUFFIX = "-c-out";
export const INPUT_SUFFIX = "-c-in";
export const WEBHOOK_CARD_ID = "webhook";
export const SAFE_TEXT_PATTERN = /^(?!^\s*$)[a-zA-Z0-9\-_\+\.\s@,():]*$/;
export const SAFE_TEXT_MESSAGE = "Only letters, numbers, and -_+.@,:() allowed";

export const generateUniqueId = (str) => {
  const timestamp = new Date().getTime();
  const combinedString = str + timestamp;
  const uniqueId = uuidv4(combinedString, uuidv4.URL);
  return uniqueId;
};

export const decodeTokenAndStoreDetails = (data) => {
  const token = data.access_token;
  const decoded = jwt_decode(token);
  localStorage.setItem(TOKEN_STORE_NAME, token);
  if (decoded.sub) {
    const subscriptionDetails = {
      id: decoded.subscription_id,
      status: decoded.subscription_status,
      email: decoded.subscription_email,
      expiry: decoded.subscription_expiry,
      planId: decoded.plan_id,
    };
    localStorage.setItem(
      SUBSCRIPTION_STORE_NAME,
      JSON.stringify(subscriptionDetails)
    );
  }
  if (data?.refresh_token) {
    localStorage.setItem(REFRESH_TOKEN_STORE_NAME, data.refresh_token);
  }
  return decoded;
};

export const buildCardsList = (list) => {
  return list.map((card) => {
    return {
      id: card?.id,
      title: card?.template?.title,
      templateId: card?.template?.id,
      templateType: card?.template?.templateType,
      outputType: card?.template?.outputType,
      initialTop: parseInt(card?.positionTop),
      initialLeft: parseInt(card?.positionLeft),
      positionTop: parseInt(card?.positionTop),
      positionLeft: parseInt(card?.positionLeft),
      form: card?.fieldValues.map((field) => {
        const templateField = card?.template?.form?.find(
          (tempField) => tempField.key === field.key
        );
        return {
          ...templateField,
          ...field,
        };
      }),
      focus: true,
    };
  });
};
export const buildArrowsList = (cards) => {
  let arrows = [];
  cards.forEach((card) => {
    card.form?.forEach((field) => {
      if (field.fromCard) {
        arrows.push({
          id: card?.id + "-" + field.key,
          start: field.value,
          end: card?.id + "-" + field.key,
        });
      }
    });
  });
  return arrows;
};
export const isFullScreen = (location) => {
  return (
    location.pathname.includes("new-bot") ||
    (location.pathname.includes("/bot") &&
      location.pathname.includes("/details"))
  );
};

export const friendlyType = (type) => {
  const lowerType = type.toLowerCase();
  const friendlyNames = {
    float: "Decimal",
    boolean: "Yes/No",
    string: "Text",
    int: "Whole Number",
    integer: "Whole Number",
    list: "List",
    any: "any",
  };
  return friendlyNames[lowerType] || "Unknown";
};

export const CATEGORY_LABELS = {
  PRICES: "Prices",
  TECHNICAL_INDICATORS: "Technical Indicators",
  FONDAMONTAL_INDICATORS: "Fondamontal Indicators",
  BASIC_NUMERICAL_OPERATIONS: "Basic Numerical Operations",
  BASIC_LOGICAL_OPERATIONS: "Basic Logical Operations",
  BASIC_TEXT_OPERATIONS: "Basic Text Operations",
  LIST_OPERATIONS: "List Operations",
  BASIC_STRATEGIES: "Basic Strategies",
  AI_MODELS: "AI Models",
  BASIC_ACTION: "Basic Action",
  CONVERTORS: "Convertors",
  VOLUME_INDICATORS: "Volume Indicators",
  TREND_INDICATORS: "Trend Indicators",
  PATTERNS_RECOGNITION: "Patterns Recognition",
};

export const deleteBot = async (id, onSuccess) => {
  try {
    const response = await apiDeleteBot({ botId: id });

    if (response.data) {
      toast.push(
        <Notification title="Bot deleted successfully" type="success" />
      );
      onSuccess();
    }
  } catch (error) {
    toast.push(
      <Notification closable title="Delete bot" type="danger" duration={10000}>
        {error?.response?.data?.error || error?.message || "Can't delete bot!"}
      </Notification>
    );
  }
};

export const startBot = async (id, onSuccess, onError) => {
  try {
    const response = await apiStartBot({ botId: id });

    if (response.data) {
      toast.push(<Notification title="Bot started" type="success" />);
      onSuccess();
    }
  } catch (error) {
    onError(error);
  }
};

export const stopBot = async (id, onSuccess, onError) => {
  try {
    const response = await apiStopBot({ botId: id });

    if (response.data) {
      onSuccess();
      toast.push(<Notification title="Bot stopped" type="success" />);
    }
  } catch (error) {
    onError(error);
  }
};

export const groupBy = (list, attribute) => {
  return list.reduce((acc, item) => {
    if (!acc[item[attribute]]) {
      acc[item[attribute]] = [];
    }
    acc[item[attribute]].push(item);
    return acc;
  }, {});
};

export const loadCache = async (dispatch) => {
  const savedTemplates = JSON.parse(localStorage.getItem(TEMPLATES_STORE_NAME));
  if (!!savedTemplates?.length) {
    dispatch(setTemplates(savedTemplates));
  }
  const savedExchanges = JSON.parse(
    localStorage.getItem(CURRENCIES_STORE_NAME)
  );
  if (!!savedExchanges) {
    dispatch(setCurrencies(savedExchanges));
  }
};

export const fillTemplates = async (dispatch) => {
  try {
    const response = await apiGetTemplates();
    if (response.data) {
      localStorage.setItem(TEMPLATES_STORE_NAME, JSON.stringify(response.data));
      dispatch(setTemplates(response.data));
    }
  } catch (errors) {
    toast.push(
      <Notification
        closable
        title="Server error"
        type="danger"
        duration={10000}
      >
        {errors?.response?.data?.error ||
          errors?.message ||
          "Can't connect to the server!"}
      </Notification>
    );
  }
};

export const fillInputTypes = async (dispatch) => {
  try {
    const response = await apiGetInputTypes();
    if (response.data) {
      dispatch(setTypesColors(response.data));
    }
  } catch (errors) {
    toast.push(
      <Notification
        closable
        title="Server error"
        type="danger"
        duration={10000}
      >
        {errors?.response?.data?.error ||
          errors?.message ||
          "Can't connect to the server!"}
      </Notification>
    );
  }
};

export const toId = (input) => {
  return input
    .replace(/& /g, "")
    .replace(/ /g, "-")
    .replace(/\//g, "-")
    .replace(/\(/g, "-")
    .replace(/\)/g, "-")
    .toLowerCase();
};

export const cardBorderColor = (cardType) => {
  switch (cardType) {
    case "INPUT":
      return "green-400";
    case "OPERATION":
      return "purple-400";
    case "ACTION":
      return "cyan-400";
    default:
      return "gray-300";
  }
};
export const calculateZoomScale = (cards, containerRef) => {
  if (!containerRef.current) {
    return 1;
  }
  const cardWidth = 250;
  const cardHeight = 300;
  const workspaceWidth = containerRef.current.offsetWidth;
  const workspaceHeight = containerRef.current.offsetHeight;

  let maxLeft = 0;
  let maxTop = 0;

  // Find the furthest right and bottom edges of the cards
  cards.forEach((card) => {
    maxLeft = Math.max(maxLeft, card.positionLeft + cardWidth);
    maxTop = Math.max(maxTop, card.positionTop + cardHeight);
  });

  // Calculate scale to fit all cards
  const scaleX = workspaceWidth / maxLeft;
  const scaleY = workspaceHeight / maxTop;
  let scale = Math.min(scaleX, scaleY);

  // Ensure scale is within the bounds 0.25 to 1
  scale = Math.min(Math.max(scale, 0.25), 1);

  return scale;
};
