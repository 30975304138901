const appConfig = {
  apiPrefix: "https://api.trabo.ai",
  paypalPlanID: "P-4HV12026VY972320BMXFXSAA",
  authenticatedEntryPathUser: "/market",
  authenticatedEntryPathAdmin: "/users",
  userUnAuthenticatedEntryPath: "/sign-in",
  adminUnAuthenticatedEntryPath: "/admin",
  tourPath: "/",
  enableMock: false,
  dataController: "Trabo Company",
};

export default appConfig;
