import React from "react";
import { HiOutlineMenuAlt3, HiOutlineMenu } from "react-icons/hi";

const NavToggle = ({ toggled, className }) => {
  return (
    <div className={className}>
      {toggled ? <HiOutlineMenu /> : <HiOutlineMenuAlt3 />}
    </div>
  );
};

export default NavToggle;
