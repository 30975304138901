import {
  SET_TEMPLATES,
  ADD_CARD,
  FOCUS_CARD,
  REMOVE_CARD,
  REMOVE_DRAFT,
  REMOVE_FOCUS_CARD,
  SET_DRAG_OVER_ITEM,
  BUILD_ARROW,
  REMOVE_ARROWS_FROM_CARD,
  REMOVE_ARROW_TO_INPUT,
  SET_TOP_LEFT,
  SET_FIELD_VALUE,
  SET_FIELD_OPTIONS,
  SET_AUTHENTICATED,
  SET_USER_INFO,
  SET_CARDS,
  SET_ARROWS,
  SHOW_HIDE_LOGS,
  SET_BOT_STATUS,
  INVALIDATE_ARROW,
  WORKSPACE_HAS_CHANGES,
  SET_INPUT_TYPES_COLORS,
  LOG_OUT,
  SET_CURRENCIES,
  SET_TRIGGER_DETAILS,
  WORKSPACE_HAS_OPTIONAL_CHANGES,
} from "../actions/mainActions";
import { v4 as uuidv4 } from "uuid";
import localStorage from "redux-persist/es/storage";
import { CURRENCIES_STORE_NAME } from "constants/app.constant";
import { generateUniqueId } from "utils/utils";

const initialState = {
  cards: [],
  arrows: [],
  typesColors: {},
  botStatus: null,
  templates: [],
  exchangesCurrencies: {},
  dragOverItem: null,
  workspaceHasChanges: false,
  workspaceHasOptionalChanges: false,
  userInfo: null,
  triggerDetails: {
    isCron: true,
    cronDetails: {
      expression: "* * * * *",
      description: "Every minute",
      isValid: true,
    },
    webhookDetails: {},
  },
  authenticated: false,
  showLogs: false,
};
const removeArrowFromOutput = (state, id, removeCardToo) => {
  let newArrows = [...state.arrows];
  let impactedFields = [];
  newArrows = newArrows.filter((arrow) => {
    if (
      !arrow.start.includes(id) &&
      (removeCardToo ? !arrow.end.includes(id) : true)
    )
      return true;
    else {
      if (arrow.start.includes(id)) {
        impactedFields.push(arrow.end);
      }
      return false;
    }
  });
  const filtredCardsList = removeCardToo
    ? [...state.cards].filter((card) => card.id !== id)
    : [...state.cards];
  let cardAfterRemoveCard = filtredCardsList.map((card) => {
    if (card?.form?.length > 0) {
      card.form.map((field) => {
        if (impactedFields.includes(`${card.id}-${field.key}`)) {
          field.fromCard = false;
        }
        return field;
      });
    }
    return card;
  });

  return {
    ...state,
    cards: cardAfterRemoveCard,
    arrows: newArrows,
    workspaceHasChanges: true,
  };
};
export const MainReducer = (
  state = initialState,
  {
    list,
    type,
    card,
    id,
    details,
    authenticated,
    userInfo,
    status,
    retainBotData,
    value,
  }
) => {
  switch (type) {
    case SET_TEMPLATES:
      return {
        ...state,
        templates: list,
      };
    case ADD_CARD:
      return {
        ...state,
        dragOverItem: null,
        templatesType: null,
        cards: [...state.cards, card],
        workspaceHasChanges: true,
      };
    case SET_CARDS:
      return {
        ...state,
        cards: [...list],
      };
    case SET_ARROWS:
      return {
        ...state,
        arrows: [...list],
      };
    case FOCUS_CARD:
      const cardsCopy = [...state.cards].map((card) => {
        if (card.id === id) {
          card.focus = true;
        } else {
          card.focus = false;
        }
        return card;
      });
      return {
        ...state,
        cards: cardsCopy,
      };
    case REMOVE_FOCUS_CARD:
      return {
        ...state,
        cards: [...state.cards].map((card) => {
          card.focus = false;
          return card;
        }),
      };
    case SET_DRAG_OVER_ITEM:
      return {
        ...state,
        dragOverItem: id,
      };
    case REMOVE_CARD:
      return removeArrowFromOutput(state, id, true);
    case REMOVE_ARROWS_FROM_CARD:
      return removeArrowFromOutput(state, id, false);
    case REMOVE_ARROW_TO_INPUT:
      const filtredArrows = [...state.arrows].filter(
        (arrow) => arrow.end !== `${details.cardId}-${details.key}`
      );
      const cardsWithUpdatedFields = [...state.cards].map((card) => {
        if (card.id === details.cardId && card?.form?.length > 0) {
          card.form.map((field) => {
            if (field.key === details.key) {
              field.fromCard = false;
              field.value = null;
            }
            return field;
          });
        }
        return card;
      });
      return {
        ...state,
        arrows: filtredArrows,
        cards: cardsWithUpdatedFields,
        workspaceHasChanges: true,
      };
    case REMOVE_DRAFT:
      if (!retainBotData) {
        return {
          ...initialState,
          authenticated: state.authenticated,
          templates: [...state.templates],
          typesColors: { ...state.typesColors },
        };
      } else {
        let oldNewIds = [];
        const newCardsList = [...state.cards].map((card) => {
          const oldId = card.id;
          card.id = card?.templateId + uuidv4();
          const newId = card.id;
          oldNewIds.push({ oldId, newId });
          return card;
        });
        const newArrowsList = [...state.arrows].map((arrow) => {
          oldNewIds.forEach((entry) => {
            arrow.start = arrow.start.replace(entry.oldId, entry.newId);
            arrow.end = arrow.end.replace(entry.oldId, entry.newId);
          });
          return arrow;
        });

        const newWebhookId = generateUniqueId(
          state.userInfo?.username || "username"
        );
        const newTriggerDetails = {
          ...state.triggerDetails,
          webhookDetails: {
            ...state.triggerDetails?.webhookDetails,
            webhookId: newWebhookId,
          },
        };
        return {
          ...state,
          cards: newCardsList,
          arrows: newArrowsList,
          botStatus: null,
          triggerDetails: newTriggerDetails,
        };
      }
    case BUILD_ARROW:
      const newArrowId = uuidv4();
      const newArrowStart = details.fromCardId;
      const newArrowEnd = `${details.toCardId}-${details.toFieldKey}`;

      const filtredArrowsList = [...state.arrows].filter(
        (arrow) => arrow.end !== newArrowEnd
      );
      const updatedCards = [...state.cards].map((card) => {
        if (card.id === details.toCardId && card?.form?.length > 0) {
          card.form.map((field) => {
            if (field.key === details.toFieldKey) {
              field.fromCard = true;
            }
            return field;
          });
        }
        return card;
      });
      return {
        ...state,
        arrows: [
          ...filtredArrowsList,
          { id: newArrowId, start: newArrowStart, end: newArrowEnd },
        ],
        cards: updatedCards,
        workspaceHasChanges: true,
      };
    case INVALIDATE_ARROW:
      let updatedArrowList = [...state.arrows];
      updatedArrowList.forEach((arrow) => {
        if (
          arrow.end.includes(details.toCardId) &&
          arrow.end.includes(details.toFieldKey) &&
          arrow.start.includes(details.fromCardId)
        ) {
          arrow.invalid = true;
        }
      });
      return {
        ...state,
        arrows: updatedArrowList,
      };
    case SET_TOP_LEFT:
      const cardsList = [...state.cards].map((card) => {
        if (card.id === details.cardId) {
          card.positionTop = details.top;
          card.positionLeft = details.left;
        }
        return card;
      });
      return {
        ...state,
        cards: cardsList,
        workspaceHasOptionalChanges: true,
      };
    case SET_FIELD_VALUE:
      const newCardsList = [...state.cards].map((card) => {
        if (card.id === details.cardId && card?.form?.length > 0) {
          card.form.map((field) => {
            if (field.key === details.key) {
              field.value = details.value;
            }
            return field;
          });
        }
        return card;
      });
      return {
        ...state,
        cards: newCardsList,
        workspaceHasChanges: true,
      };
    case SET_FIELD_OPTIONS:
      return {
        ...state,
        cards: [...state.cards].map((card) => {
          if (card.id === details.cardId && card?.form?.length > 0) {
            card.form.map((field) => {
              if (field.key === details.key) {
                field.filled_options = details.options;
                field.loading = details.loading;
              }
              return field;
            });
          }
          return card;
        }),
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: authenticated,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: userInfo,
      };
    case SHOW_HIDE_LOGS:
      return {
        ...state,
        showLogs: !state.showLogs,
      };
    case SET_BOT_STATUS:
      return {
        ...state,
        botStatus: status,
      };
    case WORKSPACE_HAS_CHANGES:
      return {
        ...state,
        workspaceHasChanges: value,
      };
    case WORKSPACE_HAS_OPTIONAL_CHANGES:
      return {
        ...state,
        workspaceHasOptionalChanges: value,
      };
    case SET_INPUT_TYPES_COLORS:
      let typeColorMapper = {};
      list.forEach((item) => {
        typeColorMapper[item.type] = item.color;
      });
      return {
        ...state,
        typesColors: typeColorMapper,
      };
    case LOG_OUT:
      return {
        ...state,
        templates: [],
        exchangesCurrencies: {},
      };
    case SET_CURRENCIES:
      let newExchangesCurrencies = { ...state.exchangesCurrencies };
      if (!details?.exchange) {
        newExchangesCurrencies = details;
      } else {
        const { exchange, newList } = details;
        newExchangesCurrencies[exchange] = newList;
        localStorage.setItem(
          CURRENCIES_STORE_NAME,
          JSON.stringify(newExchangesCurrencies)
        );
      }
      return {
        ...state,
        exchangesCurrencies: newExchangesCurrencies,
      };
    case SET_TRIGGER_DETAILS:
      return {
        ...state,
        triggerDetails: details,
      };
    default:
      return state;
  }
};
